<template>
  <div>
    <el-form :model="contractForm" ref="contractForm" label-width="220px" class="demo-ruleForm"
      size="mini" labelPosition="top">
      <el-row class="m-l-100">
        <el-col :span="3">
          <el-form-item label="Төлбөрийн хэлбэр:"
              :rules="[{ required: true, message: 'Төлбөрийн хэлбэр сонгоно уу!', trigger: 'blur' }]">
              <el-radio-group v-model="contractForm.payment_type">
                <el-radio-button label="Бүтэн төлөлт">Бүтэн төлөлт</el-radio-button>
                <el-radio-button label="Зээлээр">Зээлээр</el-radio-button> 
              </el-radio-group>
            </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="Урьдчилгаа төлбөр:"
              :rules="[{ required: true, message: 'Урьдчилгаа төлбөр оруулна уу!', trigger: 'blur' }]">
              <el-input v-model="contractForm.pay_amount">
                  </el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="22">
          <el-form-item class="lesson_select_zone"
            v-for="(lesson, index) in contractForm.contract_lessons"
            :label="'Суралцах хичээл ' + (index+1)"
            :key="index"
            :rules="{required: true, message: 'lesson can not be null', trigger: 'blur'}">
            <el-row>
              <el-col :span="7">
                <el-form-item label="Хичээл сонгоно уу"
                  :rules="[{ required: true, message: 'Хичээл заавал сонгоно уу', trigger: 'blur' }]">
                  <el-radio-group v-model="lesson.lesson_name">
                    <el-radio-button label="mat">Математик</el-radio-button>
                    <el-radio-button label="eng">Англи хэл</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="Суралцах хугацаа"
                  :rules="[ { required: true, message: 'Суралцах хугацаа', trigger: 'blur' }]">
                  <el-radio-group v-model="lesson.student_month" @change="setStudentTime(index)">
                    <el-radio-button :label="3">3 сар</el-radio-button>
                    <el-radio-button :label="6">6 сар</el-radio-button>
                    <el-radio-button :label="9">9 сар</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Суралцах нийт цаг"
                  :rules="[ { required: true, message: 'Суралцах цаг хоосон байна', trigger: 'blur' }]">
                  <el-input placeholder="Суралцах нийт цаг" v-model="lesson.student_time">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <el-form-item label="Суралцаж эхлэх өдөр"
                  :rules="[ { required: true, message: 'Суралцаж эхлэх огноог сонгоно уу', trigger: 'blur' }]">
                  <template>
                    <div class="block">
                      <el-date-picker v-model="lesson.start_date" type="date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" placeholder="Суралцах эхлэх өдөр"
                        @change="setEndDate(index)">
                      </el-date-picker>
                    </div>
                  </template>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="Суралцаж дуусах өдөр"
                  :rules="[ { required: true, message: 'Суралцаж дуусах огноог сонгоно уу', trigger: 'blur' }]">
                  <template>
                    <div class="block">
                      <el-date-picker v-model="lesson.end_date" type="date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" placeholder="Суралцах дуусах өдөр">
                      </el-date-picker>
                    </div>
                  </template>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Төлбөр"
                  :rules="[ { required: true, message: 'Төлбөрийн мэдээлэл дутуу байна', trigger: 'blur' }]">
                  <el-input placeholder="Төлбөр" v-model="lesson.payment">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Анги дүүргэлт">
              <el-radio-group v-model="lesson.eelj" size="medium">
                <el-radio-button :disabled="false" :label="1">08:00 - 09:30 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj1 }} </span> <span
                    v-else>{{ engEeljOptions.eelj1 }} </span>сул</el-radio-button>
                <el-radio-button :disabled="false" :label="2">09:30 - 11:00 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj2 }} </span> <span
                    v-else>{{ engEeljOptions.eelj2 }} </span> сул</el-radio-button>
                <el-radio-button :disabled="false" :label="3">11:00 - 12:30 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj3 }} </span> <span
                    v-else>{{ engEeljOptions.eelj3 }} </span>сул</el-radio-button>
                <el-radio-button :disabled="false" :label="4">13:30 - 15:00 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj4 }} </span> <span
                    v-else>{{ engEeljOptions.eelj4 }} </span> сул</el-radio-button>
                <el-radio-button :disabled="false" :label="5">15:00 - 16:30 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj5 }} </span> <span
                    v-else>{{ engEeljOptions.eelj5 }} </span> сул</el-radio-button>
                <el-radio-button :disabled="false" :label="6">16:30 - 18:00 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj6 }} </span> <span
                    v-else>{{ engEeljOptions.eelj6 }} </span> сул</el-radio-button>
                <el-radio-button :disabled="false" :label="7">18:00 - 19:30 <br /> <span
                    v-if="lesson.lesson_name == 'mat'"> {{ matEeljOptions.eelj7 }} </span> <span
                    v-else>{{ engEeljOptions.eelj7 }} </span> сул</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-button type="danger" @click.prevent="removelesson(lesson)">Сонгосон хичээлийг цуцлах</el-button>
          </el-form-item>
          <el-form-item class="flr">
          </el-form-item>
          <el-button size="mini" type="success" v-if="contractForm.contract_lessons.length == 1"  @click="addLesson">Хичээл нэмэх</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="22">
        <el-button type="primary" class="button" @click="submitForm('contractForm')">Хадгалах</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom.js'
export default {
  props: {
    user: Object
  },
  created () {
    this.getLessonEmptyCount()
    this.getLessonEmptyCountEnglish()
    if (this.$route.params.type === 'new') {
      console.log('Params: ', this.$route.params)
    } else if (this.$route.params.type === 'edit') {
      this.checkContractStudent()
    }
  },
  data () {
    return {
      matEeljOptions: {
        eelj1: 0,
        eelj2: 0,
        eelj3: 0,
        eelj4: 0,
        eelj5: 0,
        eelj6: 0,
        eelj7: 0
      },
      engEeljOptions: {
        eelj1: 0,
        eelj2: 0,
        eelj3: 0,
        eelj4: 0,
        eelj5: 0,
        eelj6: 0,
        eelj7: 0
      },
      activeStep: 2,
      jsonData: [],
      contractForm: {
        student_id: this.$route.params.student_id,
        parent_id: this.$route.params.parent_id,
        total_amount : 0,
        payment_type: '',
        pay_amount: 0,
        contract_lessons: [{
          lesson_name: 'mat',
          student_month: 3,
          student_time: 120,
          start_date: '',
          end_date: '',
          payment: 1470000,
          eelj: 1
        }]
      },
      tableData: []
    }
  },
  methods: {
    checkContractStudent () {
      if (this.$route.params.student_id) {
        this.$root.fullscreenLoading = true
        const payload = {
          student_id: this.$route.params.student_id
        }
        services.getStudentByContract(payload)
          .then(response => {
            if (response.status === 'success') {
              this.contractForm = response.result
              this.$root.fullscreenLoading = false
            } else {
              this.$root.fullscreenLoading = false
              notification('error', response.message)
            }
          })
      }
    },
    getLessonEmptyCount () {
      const payload = {
        lesson_contract: 1
      }
      services.getLessonContractTeacherList(payload)
        .then(response => {
          this.jsonData = response.result
          response.result.forEach(element => {
            if (element.eelj === 1) {
              this.matEeljOptions.eelj1 = this.matEeljOptions.eelj1 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 2) {
              this.matEeljOptions.eelj2 = this.matEeljOptions.eelj2 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 3) {
              this.matEeljOptions.eelj3 = this.matEeljOptions.eelj3 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 4) {
              this.matEeljOptions.eelj4 = this.matEeljOptions.eelj4 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 5) {
              this.matEeljOptions.eelj5 = this.matEeljOptions.eelj5 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 6) {
              this.matEeljOptions.eelj6 = this.matEeljOptions.eelj6 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 7) {
              this.matEeljOptions.eelj7 = this.matEeljOptions.eelj7 + (element.student_limit - element
                .students_count)
            }
          })
          // response.result.forEach(element => {
          //   console.log(element, 'e')
          //   let totalStudentLimit = 0
          //   let totalStudentCount = 0
          //   element.forEach(e => {
          //     totalStudentLimit = totalStudentLimit + e.student_limit
          //     totalStudentCount = totalStudentCount + e.student_count
          //   })
          //   element.emptyStudentCount = totalStudentLimit - totalStudentCount
          // })
          console.log(this.matEeljOptions)
        })
    },
    getLessonEmptyCountEnglish () {
      const payload = {
        lesson_contract: 0
      }
      services.getLessonContractTeacherList(payload)
        .then(response => {
          this.jsonData = response.result
          response.result.forEach(element => {
            if (element.eelj === 1) {
              this.engEeljOptions.eelj1 = this.engEeljOptions.eelj1 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 2) {
              this.engEeljOptions.eelj2 = this.engEeljOptions.eelj2 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 3) {
              this.engEeljOptions.eelj3 = this.engEeljOptions.eelj3 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 4) {
              this.engEeljOptions.eelj4 = this.engEeljOptions.eelj4 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 5) {
              this.engEeljOptions.eelj5 = this.engEeljOptions.eelj5 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 6) {
              this.engEeljOptions.eelj6 = this.engEeljOptions.eelj6 + (element.student_limit - element
                .students_count)
            } else if (element.eelj === 7) {
              this.engEeljOptions.eelj7 = this.engEeljOptions.eelj7 + (element.student_limit - element
                .students_count)
            }
          })
        })
    },
    removelesson (lesson) {
      console.log(lesson, 'lesson shuu')
      if(lesson.id){
        var payload ={ 
          id : lesson.id
        }
                services.deleteContractLesson(payload)
        .then(response => {
          if(response.status == 'success'){
                            notification('delete_contract_lesson_success')
          }
        })
      }
      const index = this.contractForm.contract_lessons.indexOf(lesson)
      if (index !== -1) {
        this.contractForm.contract_lessons.splice(index, 1)
      }
    },
    addLesson () {
      this.contractForm.contract_lessons.push({
        lesson_name: 'eng',
        student_month: 3,
        student_time: 120,
        start_date: '',
        end_date: '',
        payment: 1450000,
        eelj: 1
      })
    },
    addDays (str, days) {
      const myDate = new Date(str)
      myDate.setDate(myDate.getDate() + parseInt(days))
      return myDate.toISOString().split('T')[0]
    },
    setEndDate (i) {
      // console.log(this.addDays(this.contractForm.contract_lessons[i].start_date, (this.contractForm.contract_lessons[i].student_month * 24), 'dsadsa'))
      this.contractForm.contract_lessons[i].end_date = this.addDays(this.contractForm.contract_lessons[i].start_date, (this.contractForm.contract_lessons[i].student_month * 27))
      // console.log(this.contractForm.contract_lessons[i].end_date)
    },
    setStudentTime (i) {
      this.contractForm.contract_lessons[i].student_time = this.contractForm.contract_lessons[i].student_month * 40
      if (this.contractForm.contract_lessons[i].student_month === 6) {
        this.contractForm.contract_lessons[i].payment = 2850000
      } else if (this.contractForm.contract_lessons[i].student_month === 9) {
        this.contractForm.contract_lessons[i].payment = 4190000
      } else {
        this.contractForm.contract_lessons[i].payment = this.contractForm.contract_lessons[i].student_month * 490000
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var total_amount = 0
          this.contractForm.contract_lessons.forEach(hicheel => {
            total_amount = hicheel.payment + total_amount
          });
          this.contractForm.total_amount = total_amount
          services.createContract(this.contractForm)
            .then(response => {
              this.$root.fullscreenLoading = true
              if (response.status === 'success') {
                notification('add_user_nofitcation')
                this.$router.push({
                  params: {
                    id: response.result.student_id
                  },
                  name: 'student'
                })
                this.$root.fullscreenLoading = false
              } else if (response.status === 'failed') {
                this.$root.fullscreenLoading = true
                Object.keys(response.message).forEach((element, index) => {
                  this.$notify.error({
                    title: 'Алдаа',
                    message: response.message[element],
                    offset: (index * 150)
                  })
                  this.$root.fullscreenLoading = false
                })
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
