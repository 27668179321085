import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/layout/mainLayout.vue'
import Home from '../views/home/HomePage.vue'
import User from '@/components/user/userComponent.vue'
import userAdd from '@/components/user/component/userAdd.vue'
import LoginPage from '@/views/loginFolder/LoginPage.vue'
import resetPassword from '@/views/loginFolder/resetPassword.vue'
import regUser from '@/views/loginFolder/regUser.vue'
import AuthError from '@/components/error/authError.vue'
import store from '@/store'
import PositionListComponent from '@/components/position/positionList.vue'
import Category from '@/components/category/category.vue'
import Lesson from '@/components/lesson/lesson.vue'
import Room from '@/components/room/roomList.vue'
import Event from '@/components/event'
import LessonSchedule from '@/components/lessonSchedule/lessonScheduleList.vue'
import LessonAttendance from '@/components/lessonAttendance'
import Blogs from '@/components/blog/blogsList.vue'
import Question from '@/components/question/listQuestion.vue'
import Parents from '@/components/parents/parentsComment.vue'
import Topic from '@/components/topic/topicList.vue'
import Student from '@/components/student/studentList.vue'
import Studentadd from '@/components/student/component/studentAdd.vue'
import StudentStep1 from '@/components/student/component/studentStep1.vue'
import StudentStep2 from '@/components/student/component/studentStep2.vue'
import StudentStep3 from '@/components/student/component/studentStep3.vue'
import StudentStep4 from '@/components/student/component/studentStep4.vue'
import studentScheduleList from '@/components/student/studentScheduleList.vue'
import studentTopicList from '@/components/student/studentTopicList.vue'
import willStartStudent from '@/components/student/willStartStudent.vue'
import matStudentList from '@/components/student/matStudentList.vue'
import engStudentList from '@/components/student/engStudentList.vue'
import vacationStudentsList from '@/components/student/vacationStudentsList.vue'
// import CreatePublicUser from '@/views/studentAndParent/createPublicStudent.vue'
import StudentStep5 from '@/components/student/component/studentStep5.vue'
import UserRequestList from '@/components/userRequest/userRequestList.vue'
import CreateContractCancel from '@/components/contract/contractList.vue'
import SmsList from '@/components/sms/SmsList.vue'
import SmsHistory from '@/components/sms/SmsHistory.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: MainLayout,
  redirect: '/',
  meta: {
    requiresAuth: true
  },
  children: [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
    // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
  },
  {
    path: '/user',
    name: 'User',
    component: User
    // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
  },
  {
    path: '/useradd',
    name: 'Useradd',
    component: userAdd
    // meta: { requiresAuth: true, requiresRole: ['admin', 'operator'] }
  },
  {
    path: '/position',
    name: 'PositionListComponent',
    component: PositionListComponent
  },
  {
    path: '/category',
    name: 'CategiryList',
    component: Category
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: Lesson
  },
  {
    path: '/authError',
    name: 'AuthError',
    component: AuthError,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/room',
    name: 'roomList,',
    component: Room
  },
  {
    path: '/event',
    name: 'event,',
    component: Event
  },
  {
    path: '/lessonSchedule',
    name: 'lessonScheduleList,',
    component: LessonSchedule
  },
  {
    path: '/lessonAttendance',
    name: 'lessonAttendance,',
    component: LessonAttendance
  },
  {
    path: '/blogslist',
    name: 'blogsList,',
    component: Blogs
  },
  {
    path: '/listquestion',
    name: 'listquestion,',
    component: Question
  },
  {
    path: '/parents',
    name: 'parents',
    component: Parents
  },
  {
    path: '/userRequest',
    name: 'UserRequestList',
    component: UserRequestList
  },
  {
    path: '/contractCancel',
    name: 'CreateContractCancel',
    component: CreateContractCancel
  },
  {
    path: '/topic',
    name: 'topic',
    component: Topic
  },
  {
    path: '/student',
    name: 'student',
    component: Student
  },
  {
    path: '/studentTopicList',
    name: 'studentTopicList',
    component: studentTopicList
  },
  {
    path: '/willStartStudent',
    name: 'willStartStudent',
    component: willStartStudent
  },
  {
    path: '/matStudentList',
    name: 'matStudentList',
    component: matStudentList
  },
  {
    path: '/engStudentList',
    name: 'engStudentList',
    component: engStudentList
  },
  {
    path: '/vacationStudentsList',
    name: 'vacationStudentsList',
    component: vacationStudentsList
  },
  {
    path: '/studentScheduleList',
    name: 'studentScheduleList',
    component: studentScheduleList
  },
  {
    path: '/sms',
    name: 'SmsList',
    component: SmsList
  },
  {
    path: '/smsHistory',
    name: 'SmsHistory',
    component: SmsHistory
  },
  {
    path: '/studentadd',
    name: 'studentadd',
    component: Studentadd,
    children: [{
      path: '/studentadd',
      name: 'studentStep1',
      component: StudentStep1
    },
    {
      path: '/studentStep2',
      name: 'studentStep2',
      component: StudentStep2
    },
    {
      path: '/studentStep3',
      name: 'studentStep3',
      component: StudentStep3
    },
    {
      path: '/studentStep4',
      name: 'studentStep4',
      component: StudentStep4
    },
    {
      path: '/studentStep5',
      name: 'studentStep5',
      component: StudentStep5
    }]
  }
  ]
},
{
  path: '/publicStudentAdd',
  name: 'StudentaddPublic',
  component: Studentadd,
  children: [{
    path: '/publicStudentAdd1',
    name: 'publicStudentAdd1',
    component: StudentStep1
  },
  {
    path: '/publicStudentAdd2',
    name: 'publicStudentAdd2',
    component: StudentStep2
  },
  {
    path: '/publicStudentAdd5',
    name: 'publicStudentAdd5',
    component: StudentStep5
  }
]
},
{
  path: '/login',
  name: 'LoginPage',
  component: LoginPage,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/resetPassword',
  name: 'resetPassword',
  component: resetPassword,
  meta: {
    requiresAuth: false
  }
},
{
  path: '/regUser',
  name: 'regUser',
  component: regUser,
  meta: {
    requiresAuth: false
  }
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta.requiresAuth, 'too')

  if (to.meta.requiresAuth && !store.state.idToken) {
    next({
      name: 'LoginPage'
    })
  } else if (!to.meta.requiresAuth) {
    next()
  } else {
    // console.log(asyncCall(), 'sda')
    if (store.state.user) {
      if (store.state.user.name != null) {
        next()
      } else {
        next({
          name: 'AuthError'
        })
      }
    } else {
      next()
    }
  }
})

export default router
