import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/main.scss'
import '../src/assets/scss/theme/index.css'
import locale from 'element-ui/lib/locale/lang/mn'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: 'Дэлхийн мэдлэг академи гэрээ',
  specs: [
    'titlebar=no',
    'scrollbars=yes'
  ],
  styles: [
    // './src/components/contract/c.css' // <- inject here
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(VueHtmlToPaper, options)
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyA5xaazgOddGDiGs3b-DKlcr_Lri6mBr8E',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})
Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})
new Vue({
  router,
  store,
  data: {
    studentVacation: false,
    showMenuDrawerStatus:false,
    fullscreenLoading: false,
    addWareHouseStep: 1,
    selectedBranchUuid: null,
    positionCreateDialog: false,
    categoryCreatDialog: false,
    createLessonDialog: false,
    createRoomDialog: false,
    createLessonScheduleDialog: false,
    createBlogDialog: false,
    createQuestionDialog: false,
    createCommentDialog: false,
    createTopicDrawer: false,
    studentDialog: false,
    studentStatus: false,
    img_url: null,
    studenList: false,
    createStudentTopicDrawer: false,
    editDrawer: false,
    editStudentDrawer: false,
    studentList: false,
    ChangeStatusStudent: false,
    getStudentContractPageDialog: false,
    studentPayment: false,
    smsTemplateCreateDialog: false,
    sendCustomNumberSmsDialog: false,
    activeTab: '10',
    sendSmsResultDialog: false,
    studentDetailDialogVariable: false,
    attendaceDetailDialog:false
  },
  render: h => h(App)
}).$mount('#app')
